<template>
  <section class="ftco-section">
    <div class="auth-container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="wrap d-md-flex align-items-center">
            <transition name="fade" mode="out-in">
              <router-view class="login-wrap"></router-view>
            </transition>
            <div class="align-self-center">
              <img src="/images/library_new_1.png" alt="" class="auth-img" />
            </div>
          </div>
        </div>
      </div>
      <h6 class="text-left" style="text-indent: 100px;"> Designed In collaboration with <b>N<span class="nita-logo-green">IT</span>A</b></h6>
    </div>
  </section>
</template>
<style scoped lang="scss">
@import "@/assets/custom_scss/main.scss";
.wrap {
  width: 100%;
  position: relative;
}
.login-wrap {
  position: relative;
  width:55%;
  background: transparent;
}
.ftco-section {
  padding: 6.45em 0;
  background: linear-gradient(90deg,var(--dash-white) 66%,var(--dash-red) 34%);

}
@media  screen and (max-width:480px) {
  .login-wrap{
    width: 100%;
  }
}
@media screen and (max-width: 1280px){
  .ftco-section{
    padding:3.58em 0;
  }
}
.auth-container {
  max-width:960px;
  background:transparent;
  margin-right: auto;
  margin-left: auto;
}
.auth-img {
  object-fit: cover;
  object-position: center center;
  border-radius: 2.5%;
  height:78vh;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.nita-logo-green{
  color: #afc737;
}
</style>
<script>
export default {};
</script>